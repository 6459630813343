export default class ToggleAction {
    trigger = '[data-hc-trigger="toggle"]'

    constructor() {
        const triggers = document.querySelectorAll(this.trigger)
        triggers.forEach((trigger) => {
            trigger.addEventListener('click', (e) => {
                const target =
                    e.currentTarget.href === undefined
                        ? e.currentTarget.dataset.target
                        : e.currentTarget.getAttribute('href')
                const display =
                    document.querySelector(target).offsetWidth > 0 &&
                    document.querySelector(target).offsetHeight > 0
                        ? 'none'
                        : 'block'
                document.querySelector(target).style.display = display
            })
        })
    }
}
