import * as BurgerMenuEnum from '../enumerators/BurgerMenuEnum'

export default class BurgerMenuComponent {
    trigger = '[data-hc-trigger="menu"],[data-hc-menu]'
    triggerSubmenu = '[data-hc-trigger="submenu"]'
    menuEntry = '[data-hc-id="menu-entry"]'

    constructor() {
        $(this.trigger).on('click', (e) => {
            if ($('body').hasClass(BurgerMenuEnum.MENU_IS_OPEN_CLASS)) {
                this.closeMenu(e)
            } else {
                this.openMenu(e)
            }
        })
        $(this.triggerSubmenu).on('click', (e) => {
            if (
                $(e.currentTarget)
                    .parents(this.menuEntry)
                    .hasClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
            ) {
                this.closeSubmenu($(e.currentTarget).parents(this.menuEntry).get(0))
            } else {
                this.openSubmenu($(e.currentTarget).parents(this.menuEntry).get(0))
            }
        })
    }

    openMenu(e) {
        $(e.currentTarget).addClass(BurgerMenuEnum.CROSS_ACTIVE_CLASS)
        $('body').addClass(BurgerMenuEnum.MENU_IS_OPEN_CLASS)
    }

    closeMenu(e) {
        $(e.currentTarget).removeClass(BurgerMenuEnum.CROSS_ACTIVE_CLASS)
        $('body').removeClass(BurgerMenuEnum.MENU_IS_OPEN_CLASS)
        $(this.menuEntry).removeClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
    }

    openSubmenu(e) {
        $(e).addClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
    }

    closeSubmenu(e) {
        $(e).removeClass(BurgerMenuEnum.SUBMENU_IS_OPEN_CLASS)
    }
}
