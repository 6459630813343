import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'
import AutoHiddingNavAction from './actions/AutoHiddingNavAction'
import DropdownAction from './actions/DropdownAction'
import ToggleAction from './actions/ToggleAction'
import SliderComponent from './components/SliderComponent'

import apiFetch from '@wordpress/api-fetch'
import BurgerMenuComponent from './components/BurgerMenuComponent'
import ParallaxComponent from './components/Parallax'

document.addEventListener('DOMContentLoaded', async () => {
    new DropdownAction()
    new AutoHiddingNavAction()
    new ToggleAction()
    new SliderComponent()
    new ParallaxComponent()
    new BurgerMenuComponent()

    const frontpageSlider = await Swiper.create('#pages-list-slider', {
        slidesPerView: '1',
        spaceBetween: 10,
        freeMode: true,
        navigation: {
            nextEl: '.swiper__arrows__next',
            prevEl: '.swiper__arrows__prev',
        },
        breakpoints: {
            750: {
                slidesPerView: '2',
                spaceBetween: 20,
            },
            1200: {
                slidesPerView: '4',
                spaceBetween: 30,
            },
            1450: {
                slidesPerView: '4',
                spaceBetween: 40,
            },
        },
    })

    const inspirationSlider = await Swiper.create('#inspirations-list-slider', {
        slidesPerView: '1',
        mousewheel: {
            forceToAxis: true,
        },
        autoplay: false,
        lazy: true,
        watchSlidesProgress: true,
        grabCursor: true,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper__arrows__next',
            prevEl: '.swiper__arrows__prev',
        },
        breakpoints: {
            750: {
                slidesPerView: '1',
                spaceBetween: 20,
            },
            1200: {
                slidesPerView: '1',
                spaceBetween: 30,
            },
            1450: {
                slidesPerView: '1',
                spaceBetween: 40,
            },
        },
    })

    /* Hover menu --------------------- */
    const items = $('.maxi-menu > .maxi-menu__entry')

    // nav
    $(items).on('mouseenter', () => {
        $('body').addClass()
    })

    $(items).on('mouseleave', () => {
        $(items).removeClass('opacity')
    })

    /* Strate instagram --------------------- */
    if ($('[data-id="social-wall"]').length > 0 && !$('[data-id="social-wall"]').is(':hidden')) {
        apiFetch({
            path: Customer.irisSsoInstagramPath,
            method: 'POST',
            data: { limit: 7 },
        })
            .then((result) => {
                $('[data-id="social-wall"]').html(result.render)
                import(
                    '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component' /* webpackChunkName: "scripts/hc_lazyload" */
                ).then(({ default: hc_lazyload }) => {
                    hc_lazyload()
                })
            })
            .catch(() => {
                $('[data-id="social-wall"]').html(Customer.irisSsoInstagramError)
            })
    }

    // Random
    if (window.location.hostname.match(/.iris.io/)) {
        $('img').on('error', function (e) {
            const src = $(this).attr('src')
            const new_src = src.replace(
                'https://otdegien.mmontineri.iris.io/',
                'https://otdegien.preprod5.irislab.top/',
            )
            $(this).attr('src', new_src)
        })
    }
})
