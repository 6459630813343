import * as BurgerMenuEnum from '../enumerators/BurgerMenuEnum'

export default class AutoHiddingNavAction {
    header = '[data-hc-trigger="hide-to-scroll"]'
    scrolling = false
    previousTop = 0
    currentTop = 0
    scrollDelta = 10
    scrollOffset = 50

    constructor() {
        $(window).on('scroll', () => {
            if (!$('body').hasClass(BurgerMenuEnum.MENU_IS_OPEN_CLASS)) {
                if (!this.scrolling) {
                    this.scrolling = true
                    if (!window.requestAnimationFrame) {
                        setTimeout(() => {
                            this.autoHideHeader()
                        }, 250)
                    } else {
                        requestAnimationFrame(() => {
                            this.autoHideHeader()
                        })
                    }
                }
            }
        })
    }

    autoHideHeader() {
        const currentTop = $(window).scrollTop()

        this.checkSimpleNavigation(currentTop)
        this.previousTop = currentTop
        this.scrolling = false
    }

    checkSimpleNavigation(currentTop) {
        if (this.previousTop - currentTop > this.scrollDelta) {
            $(this.header).removeClass('is-hidden')
        } else if (
            currentTop - this.previousTop > this.scrollDelta &&
            currentTop > this.scrollOffset
        ) {
            $(this.header).addClass('is-hidden')
        }

        if (currentTop > 0) {
            if (!$(this.header).hasClass('is-sticky') && $(this.header).hasClass('is-hidden')) {
                $(this.header).addClass('is-sticky')
            }
        } else {
            $(this.header).removeClass('is-sticky')
        }
    }
}
